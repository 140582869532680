'use client';

import { usePathname } from 'next/navigation';
import { Fragment, useMemo } from 'react';

import { IComponentBase, ISeo } from '@lamesarv-sdk/types';
import { twMerge } from 'tailwind-merge';

export interface IComponentHeader extends IComponentBase {
  title?: string;
  subtitle?: string;
  backgroundUrl?: string;
  seo?: ISeo;
}

export const Header = (props: IComponentHeader) => {
  const pathname = usePathname();

  const breadcrumbs = useMemo(() => {
    let url = '';

    return pathname.split('/').map((path) => {
      if (path) {
        url += `/${path}`;
      }
      return {
        title: path || 'Home',
        url: path ? url : '/',
      };
    });
  }, [pathname]);

  return (
    <div className={twMerge('relative bg-orange-600 p-4 md:p-8 text-white', props.className)}>
      {props.backgroundUrl && (
        <div
          className="absolute inset-0 bg-center opacity-20"
          style={{
            backgroundImage: `url('${props.backgroundUrl}')`,
            backgroundSize: '150%',
          }}
        />
      )}
      <div className="relative max-w-7xl mx-auto">
        <div className="text-xs uppercase">
          {breadcrumbs.map((breadcrumb, index) => {
            return (
              <Fragment key={index}>
                <a href={breadcrumb.url} target="_self" className="">
                  {breadcrumb.title}
                </a>
                {index < breadcrumbs.length - 1 && ' / '}
              </Fragment>
            );
          })}
        </div>
        <h1 className="text-3xl font-bold mt-0 md:mt-4">{props.title || props.seo.title || 'RVShowUSA'}</h1>
        {props.subtitle && <h2 className="text-md font-bold mt-0 opacity-75">{props.subtitle}</h2>}
      </div>
    </div>
  );
};
